"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CatalogSearch = void 0;
const react_1 = __importStar(require("react"));
const react_bootstrap_1 = require("react-bootstrap");
const catalog_service_1 = require("./catalog.service");
const lodash_1 = require("lodash");
const react_router_dom_1 = require("react-router-dom");
const CatalogSearch = ({ footer }) => {
    const [items, setItems] = (0, react_1.useState)([]);
    const [searchTerm, setSearchTerm] = (0, react_1.useState)("");
    const [loading, setLoading] = (0, react_1.useState)(false);
    const searchItems = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const items = yield catalog_service_1.CatalogService.searchCatalog(searchTerm);
            setItems(items !== null && items !== void 0 ? items : []);
        }
        finally {
            setLoading(false);
        }
    });
    (0, react_1.useEffect)(() => {
        const doSearch = (0, lodash_1.debounce)(() => {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                yield searchItems();
            }))();
        }, 300);
        doSearch();
        // Cleanup function to cancel the debounce on unmount
        return () => doSearch.cancel();
    }, [searchTerm]); // This
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_bootstrap_1.Card, { className: 'pt-2' },
            react_1.default.createElement(react_bootstrap_1.Card.Header, { className: `min-h-0 ${searchTerm.length === 0 && 'border-0'} px-2` },
                react_1.default.createElement(react_bootstrap_1.InputGroup, null,
                    react_1.default.createElement(react_bootstrap_1.InputGroup.Text, { className: 'border-0 bg-transparent me-5' },
                        react_1.default.createElement("i", { className: "ki-duotone ki-magnifier fs-1 position-absolute" },
                            react_1.default.createElement("span", { className: "path1" }),
                            react_1.default.createElement("span", { className: "path2" }))),
                    react_1.default.createElement(react_bootstrap_1.Form.Control, { value: searchTerm, onChange: handleSearchChange, placeholder: "Search products", className: 'border-0 ps-0 py-0 py-md-3' }))),
            searchTerm.length > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(react_bootstrap_1.Card.Body, null,
                    loading && (react_1.default.createElement("div", { className: 'd-flex justify-content-center text-primary' },
                        react_1.default.createElement("span", { className: 'indicator-progress fs-2', style: { display: 'block' } },
                            react_1.default.createElement("span", { className: 'spinner-border spinner-border-sm align-middle', style: { display: 'block', width: '25px', height: '25px' } })))),
                    !loading && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("div", { className: 'd-flex align-items-center' },
                            react_1.default.createElement("h3", { className: 'h5 m-0 d-inline' }, "Results"),
                            react_1.default.createElement("span", { className: 'badge badge-primary rounded-4 px-4 ms-4 py-1' }, items.length)),
                        react_1.default.createElement("div", { className: 'mt-5 catalog-products' }, items.map(i => (react_1.default.createElement("div", { className: 'd-flex mt-5 catalog-product' },
                            react_1.default.createElement(react_router_dom_1.Link, { to: `/catalog/${i.id}` },
                                react_1.default.createElement("div", { className: 'me-5', style: {
                                        width: '85px',
                                        height: '85px',
                                        minWidth: '85px',
                                        minHeight: '85px',
                                        backgroundColor: 'white',
                                        borderRadius: '0.5em',
                                        backgroundImage: `url('${i.imageUrl}')`,
                                        backgroundSize: '80%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                    } })),
                            react_1.default.createElement("div", { className: 'd-flex justify-content-start flex-column' },
                                react_1.default.createElement("span", { className: 'text-muted' }, i.category.name),
                                react_1.default.createElement(react_router_dom_1.Link, { to: `/catalog/${i.id}`, className: 'text-dark fw-semibold text-hover-primary mb-1 fs-5' }, i.title),
                                react_1.default.createElement("span", { className: 'text-muted small' }, i.colorway && i.sku ? (react_1.default.createElement(react_1.default.Fragment, null,
                                    i.colorway,
                                    " - ",
                                    i.sku)) : i.colorway ? (i.colorway) : i.sku ? (i.sku) : null))))))))),
                react_1.default.createElement(react_bootstrap_1.Card.Footer, { className: 'text-end py-5' }, footer))))));
};
exports.CatalogSearch = CatalogSearch;
